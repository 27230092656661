@import './theme.scss';

* {
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  height: 100%;
}

@-moz-document url-prefix() {
  html {
    scroll-behavior: auto;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
  font-family: var(--font-family);
}

@media print {
  .purechat-chatbox {
    display: none;
  }

  body {
    -webkit-print-color-adjust: exact;
  }
}

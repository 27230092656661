:root {
  --white-color: white;
  --light: #F8F9FA;
  --black-color: black;
  --yellow-color: #ffce4f;
  --purple-color: #5249d9;

  --ocean: #4a68ed;
  --ocean-light: #9892ff;

  --slight-yellow: #ffffef;

  --gradient-black-color: linear-gradient(
    90deg,
    #201f1e -0.34%,
    #495057 99.66%
  );
  --purple-color: rgb(74, 104, 237);
  --purple-color-hover: rgb(87, 129, 247);
  --error: #ed0b0b;
  --blue-color: #339af0;
  --primary-button: linear-gradient(90deg, #201f1e -0.34%, #495057 99.66%);
  --secondary-button: linear-gradient(270deg, #4a68ed 11.71%, #5249d9 100%);
  --orange-gradient: linear-gradient(180deg, #fcc419 0%, #f59f00 100%);
  --static-tooltip-arrow: #f59f00;
  --static-tooltip-arrow_bottom: #fcc419;
  --navmenu-link-hover: rgba(217, 217, 217, 0.22);
  --navmenu-link: #575757;
  --header-link-hover: rgba(248, 249, 250, 0.5);
  --header-link-active: rgba(78, 89, 227, 1);
  --styled-tooltip-default: #000000;
  --styled-tooltip-error: #ed0b0b;
  --font-family: 'Montserrat', sans-serif;

  // Page type tag colors
  --forum-tag-color: #8037ca;
  --forum-tag-color-light: #8037ca26;
  --social-tag-color: #2651c0;
  --social-tag-color-light: #2651c026;
  --webshop-tag-color: #f9af21;
  --webshop-tag-color-light: #f9af2126;
  //Gradients
  --gradient-yellow: linear-gradient(90deg, #f6d365 0%, #fda085 100%);
  --gradient-green: linear-gradient(90deg, #57cbaf 0%, #2fa84f 100%);
  --gradient-purple: linear-gradient(90deg, #80688e 0%, #4f586a 100%);
  --gradient-blue: linear-gradient(90deg, #4a68ed 0%, #2e429a 100%);
  --gradient-light-purple: linear-gradient(90deg, #aa4aed 0%, #bb77e2 100%);
  --gradient-brown: linear-gradient(90deg, #a96733 0%, #773021 100%);
  --gradient-orange: linear-gradient(90deg, #fda085 0%, #c18556 100%);

  //Font colors
  --font-primary: var(--black-color);
  --font-disabled: #a6a6a6;

  //State colors
  --state-error: rgb(250, 82, 82);
  --state-error-light: rgba(250, 82, 82, 0.2);
  --state-success: rgb(81, 207, 102);
  --state-success-light: rgba(81, 207, 102, 0.1);
  --state-pending: rgb(233, 182, 27);
  --state-pending-light: rgba(233, 182, 27, 0.1);

  //Table colors
  --table-header: transparent;
  --table-background: var(--white-color);
  --table-border: #cacaca;
  --page-indicator-color: #949494;
  --tab-indicator-background: #E9ECEF;
  --tab-indicator-color: #676767;
  --table-search-color: #7d7d7d;
  --table-icon-disabled: #a1a6af;
  --table-navigation-icon-border-color: #dadada;
  --table-navigation-icon-background: #f4f4f4;

  //Button colors
  --button-disabled: #c2c2c2;
  --button-background: rgba(244, 244, 244, 0.6);
  --button-border-color: #c8c8c8;
  --button-yellow-border: #fcc419;
  --button-font-color: #706f6f;

  //Filter colors
  --filter-border: #e0e0e0;
  --filter-clear-color: var(--purple-color);
  --filter-apply-color: var(--purple-color);
  --filter-divider-color: #e1e1e1;
  --filter-label-color: var(--black-color);

  //Checkbox colors
  --checkbox-border: #c0c0c0;
  --checkbox-color: #4b4b4b;

  //Datepicker colors
  --datepicker-label: #878787;
  --datepicker-date: var(--black-color);

  //Search colors
  --state-error: hsla(4, 53%, 43%, 1);
  --state-error-light: rgba(168, 59, 52, 0.14);
  --state-success: hsla(136, 53%, 43%, 1);
  --state-success-light: rgba(52, 168, 83, 0.14);
  --state-pending: hsla(45, 82%, 51%, 1);
  --state-pending-light: rgba(252, 196, 25, 0.1);

  --search-submit-dark: linear-gradient(90deg, #201f1e -0.34%, #495057 99.66%);
  --search-submit: #fcc419;

  //Multiselect
  --mutli-select-border-color: #cbcbcb;
  --mutli-select-checkbox-border-color: #d9d9d9;
  --mutli-select-font-color: #100f0f;

  //Month picker
  --month-picker-button-border: #ebebeb;
  --month-picker-button-arrow: #818181;

  //Header size by breakpoints
  --header-height-desktop: 86px;
  --header-height-tablet: 140px;
  --header-height-mobile: 86px;

  // Text fields
  --text-field-disabled: #f2f2f2;

  --border-color: #e0e0e0;
  --text-placeholder: #adb5bd;

  // Backgrounds
  --bg-grey: #f2f2f2;

  // Heights
  --header-height: 5rem;
}

.MuiAutocomplete-option {
  font-size: 16px !important;
  padding: 6px 8px !important;
  font-weight: normal;
  line-height: 28px;
}

.MuiAutocomplete-option.Mui-focused {
  color: var(--white-color);
  background-color: var(--purple-color) !important;
}
